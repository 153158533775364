import { css } from "styled-components/macro";
import { Button } from "../Button";
import { Confirm } from "../Confirm";

interface ToolbarProps {
  onRestart: () => void;
}

export function Toolbar({ onRestart }: ToolbarProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        padding-top: 0;
      `}
    >
      <Confirm
        title="Restart game"
        content="Are you sure you want to restart the game?"
        onConfirm={onRestart}
      >
        {(trigger) => (
          <Button variant="raised" color="green" onClick={trigger}>
            New game
          </Button>
        )}
      </Confirm>
    </div>
  );
}
