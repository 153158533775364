import styled from "styled-components";
import { darken } from "polished";
import { easeOutQuad } from "../../utils/easings";

export interface ProgressBarProps {
  progress: number;
  team: "red" | "blue";
}

export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  width: 1.5rem;
  height: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    transition: all 200ms ${easeOutQuad};
    border-radius: 4px;
    box-shadow: 0 6px 0 0
      ${(props) =>
        darken(
          0.05,
          props.progress === 0
            ? props.theme.colors.neutral24
            : props.theme.colors[props.team]
        )};
  }

  &::before {
    background: ${(props) => props.theme.colors.neutral24};
  }

  &::after {
    top: ${(props) => 100 - props.progress}%;

    background: ${(props) => props.theme.colors[props.team]};
  }
`;
