import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { defaultTheme } from "../../themes/default";
import { Game } from "../Game";
import { Home } from "../Home";

export function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Route exact path="/" children={<Home />} />
        <Route exact path="/g/:gameId" children={<Game />} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
