import { shuffle, uniqueId } from "lodash";

import { Color, Word } from "../models/GameState";
import { pickRandom } from "./pickRandom";
import wordList from "../data/wordlist.json";

export function createGameState() {
  const words: { [id: string]: Word } = {};

  let teams: Color[] = [];
  for (let i = 0; i < 9; i++) {
    teams[i] = "red";
  }
  for (let i = 9; i < 17; i++) {
    teams[i] = "blue";
  }
  for (let i = 17; i < 24; i++) {
    teams[i] = "white";
  }
  teams[24] = "black";
  teams = shuffle(teams);

  pickRandom(wordList, 25).forEach((word, index) => {
    words[uniqueId()] = {
      word,
      color: teams[index],
      revealed: false,
    };
  });

  return {
    words,
  };
}
