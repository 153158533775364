import { useState } from "react";
import { FiTarget } from "react-icons/fi";
import { css } from "styled-components/macro";

import { GameState } from "../../models/GameState";
import { Role } from "../../models/Role";
import { Button } from "../Button";
import { OptionGroup } from "../OptionGroup/OptionGroup";

const RoleColors = {
  operative: "green",
  spymaster: "orange",
  gamemaster: "purple",
};

interface LobbyProps {
  gameState: GameState;
  onJoin: (role: Role) => void;
}

export function Lobby({ gameState, onJoin }: LobbyProps): JSX.Element | null {
  const [joinAs, setJoinAs] = useState<Role>();

  return (
    <div
      css={css`
        height: 100%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > :first-child {
          margin-bottom: 1rem;
        }
      `}
    >
      <OptionGroup
        value={joinAs ? [joinAs] : []}
        onChange={(value) => setJoinAs(value[0] as Role)}
        options={[
          {
            key: "operative",
            icon: <FiTarget size={72} />,
            label: "Operative",
            color: RoleColors.operative,
          },
          {
            key: "spymaster",
            icon: <FiTarget size={72} />,
            label: "Spymaster",
            color: RoleColors.spymaster,
          },
          {
            key: "gamemaster",
            icon: <FiTarget size={72} />,
            label: "Gamemaster",
            color: RoleColors.gamemaster,
          },
        ]}
      />
      <Button
        css={css`
          margin-top: 0.5rem;
          width: 100%;
          font-size: 1.5rem;
        `}
        variant="raised"
        color={joinAs ? RoleColors[joinAs] : undefined}
        disabled={!joinAs}
        onClick={() => (joinAs ? onJoin(joinAs) : null)}
      >
        Join
      </Button>
    </div>
  );
}
