import { ReactNode, useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { Button } from "../Button";
import { Modal } from "../Modal";

interface ConfirmProps {
  title?: ReactNode;
  content?: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  children?: (trigger: () => void) => any;
  onConfirm: () => void;
}

export function Confirm({
  title,
  content,
  confirmLabel,
  cancelLabel,
  children,
  onConfirm,
}: ConfirmProps) {
  const [isShown, setShown] = useState(false);

  const hide = useCallback(() => setShown(false), [setShown]);
  const show = useCallback(() => setShown(true), [setShown]);

  const handleConfirm = useCallback(() => {
    hide();
    onConfirm();
  }, [hide, onConfirm]);

  return (
    <>
      <Modal
        visible={isShown}
        header={title || "Confirmação"}
        footer={
          <>
            <Button
              variant="raised"
              css={css`
                margin-right: 1rem;
              `}
              onClick={hide}
            >
              {cancelLabel || "Cancelar"}
            </Button>
            <Button variant="raised" color="primary" onClick={handleConfirm}>
              {confirmLabel || "Confirmar"}
            </Button>
          </>
        }
        onClose={hide}
      >
        {content || "Tem certeza que deseja continuar?"}
      </Modal>
      {children && children(show)}
    </>
  );
}
