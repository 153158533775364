import styled from "styled-components/macro";
import { darken } from "polished";
import { easeOutQuad } from "../../utils/easings";

export interface ButtonProps {
  variant?: "default" | "raised";
  color?: string;
  disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
  outline: none;
  border: none;
  padding: ${(props) => (props.variant === "raised" ? "1rem 2rem" : "none")};
  font-size: 1.5rem;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.variant === "raised"
      ? props.theme.colors[props.color || "neutral32"]
      : "none"};
  color: ${(props) => props.theme.colors.neutral100};
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.variant === "raised"
      ? `0 6px 0 0 ${darken(
          0.05,
          props.theme.colors[props.color || "neutral32"]
        )}`
      : "none"};

  transition: all 60ms ${easeOutQuad};
  position: relative;
  top: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    opacity: 0;
    border-radius: 4px;
  }

  &:hover,
  &:focus {
    &::after {
      opacity: ${(props) => (props.disabled ? 0 : 0.1)};
    }
  }
  &:active {
    box-shadow: ${(props) =>
      props.variant === "raised"
        ? `0 3px 0 0 ${darken(
            0.05,
            props.theme.colors[props.color || "neutral32"]
          )}`
        : "none"};
    top: 4px;
  }
`;
