import { useCallback } from "react";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";

import { firestore } from "../../firebase";
import { createGameState } from "../../utils/createGameState";
import { Button } from "../Button";

export function Home(): JSX.Element | null {
  const history = useHistory();

  const handleCreateGame = useCallback(async () => {
    const newGame = await firestore.collection("games").add(createGameState());

    history.push(`/g/${newGame.id}`);
  }, [history]);

  return (
    <div
      css={css`
        height: 100%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <Button
        css={css`
          font-size: 1.5rem;
        `}
        variant="raised"
        color="primary"
        onClick={handleCreateGame}
      >
        Create Game
      </Button>
    </div>
  );
}
