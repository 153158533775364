import { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Button } from "../Button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const OptionContent = styled.div`
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

const OptionIcon = styled.div`
  margin-bottom: 0.5rem;
`;

export interface Option {
  key: string;
  label?: string;
  icon?: ReactNode;
  color?: string;
}

export interface OptionGroupProps {
  options: Option[];
  multiple?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
}

export function OptionGroup({
  options,
  multiple,
  value,
  onChange,
}: OptionGroupProps): JSX.Element | null {
  const [selection, setSelection] = useState<{ [key: string]: boolean }>({});

  // when "value" changes, update the selection object accordingly
  useEffect(() => {
    if (!value) {
      return;
    }

    const newSelection = value.reduce<{ [key: string]: boolean }>(
      (selection, val) => ({ ...selection, [val]: true }),
      {}
    );
    setSelection(newSelection);
  }, [value]);

  // if more than 1 option selected but "multiple" is not active,
  // unselect everything but the first option
  useEffect(() => {
    const keys = Object.keys(selection);
    if (keys.length > 1 && !multiple) {
      setSelection({ [keys[0]]: true });
    }
  }, [selection, multiple]);

  const isSelected = useCallback(
    (optionKey) => {
      return Boolean(selection[optionKey]);
    },
    [selection]
  );

  const toggleSelected = useCallback(
    (optionKey) => {
      // if "multiple": copy current selection into a new selection object
      // else: create an empty selection object
      const newSelection = multiple ? { ...selection } : {};

      // add or remove the option from the selection object
      if (selection[optionKey]) {
        delete newSelection[optionKey];
      } else {
        newSelection[optionKey] = true;
      }

      setSelection(newSelection);

      if (onChange) {
        onChange(Object.keys(newSelection));
      }
    },
    [selection, multiple, onChange, setSelection]
  );

  return (
    <Container>
      {options &&
        options.map((option) => (
          <Button
            key={option.key}
            variant="raised"
            color={
              isSelected(option.key) ? option.color || "yellow" : undefined
            }
            onClick={() => toggleSelected(option.key)}
          >
            <OptionContent>
              {option.icon && <OptionIcon>{option.icon}</OptionIcon>}
              {option.label && <span>{option.label}</span>}
            </OptionContent>
          </Button>
        ))}
    </Container>
  );
}
