import { css } from "styled-components/macro";

import { GameState, Word } from "../../models/GameState";
import { Card } from "../Card";
import { Grid } from "../Grid";
import { ProgressBar } from "../ProgressBar";

export interface BoardProps {
  gameState: GameState;
  flipWord: (wordId: string, word: Word) => void;
  canSpy?: boolean;
  canFlip?: boolean;
}

export function Board({
  gameState,
  flipWord,
  canSpy,
  canFlip,
}: BoardProps): JSX.Element | null {
  const scores = Object.values(gameState.words).reduce<{
    [key: string]: number;
  }>(
    (currentScores, word) => {
      if (!word.revealed) {
        return currentScores;
      }

      const currentValue = currentScores[word.color] || 0;
      return {
        ...currentScores,
        [word.color]: currentValue + 1,
      };
    },
    { red: 0, blue: 0, white: 0, black: 0 }
  );

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: row;
        margin: 1rem;
      `}
    >
      <ProgressBar progress={Math.floor((scores.red / 9) * 100)} team="red" />

      <Grid
        css={css`
          margin: -0.5rem 0.5rem;
          height: calc(100% + 1rem);
        `}
      >
        {gameState.words &&
          Object.keys(gameState.words)
            .sort()
            .map((wordId) => {
              const word = gameState.words[wordId];
              return (
                <Card
                  key={wordId}
                  label={word.word}
                  color={canSpy || word.revealed ? word.color : undefined}
                  shadowed={canSpy && word.revealed}
                  onClick={canFlip ? () => flipWord(wordId, word) : undefined}
                />
              );
            })}
      </Grid>

      <ProgressBar progress={Math.floor((scores.blue / 8) * 100)} team="blue" />
    </div>
  );
}
