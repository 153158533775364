import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDF4jkLTW2-Rq5OAkJOuqUYvXOjNKUUBBs",
  authDomain: "codenames-ac7ed.firebaseapp.com",
  projectId: "codenames-ac7ed",
  storageBucket: "codenames-ac7ed.appspot.com",
  messagingSenderId: "156689572469",
  appId: "1:156689572469:web:b3e3e7150d94310aac4e3c",
  measurementId: "G-HWZZB19WRW",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };
