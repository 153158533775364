import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { firestore } from "../firebase";
import { GameState, Word } from "../models/GameState";
import { createGameState } from "../utils/createGameState";
import { RecursivePartial } from "../utils/RecursivePartial";

export function useGame() {
  const { gameId } = useParams<{ gameId: string }>();

  const [gameState, setGameState] = useState<GameState>();

  useEffect(() => {
    return firestore
      .collection("games")
      .doc(gameId)
      .onSnapshot({
        next: (snapshot) => {
          setGameState(snapshot.data() as GameState);
        },
        error: console.error,
      });
  }, [gameId]);

  const updateGameState = useCallback(
    async (newState: RecursivePartial<GameState>, merge?: boolean) => {
      await firestore.collection("games").doc(gameId).set(newState, { merge });
    },
    [gameId]
  );

  const restartGame = useCallback(async () => {
    await updateGameState(createGameState());
  }, [updateGameState]);

  const flipWord = useCallback(
    async (wordId: string, word: Word) => {
      await updateGameState(
        {
          words: {
            [wordId]: {
              revealed: !word.revealed,
            },
          },
        },
        true
      );
    },
    [updateGameState]
  );

  return { gameState, restartGame, flipWord };
}
