import { css } from "styled-components/macro";

import { Button } from "../Button";

const ColorMap: { [from: string]: string } = {
  red: "red",
  blue: "blue",
  white: "neutral96",
  black: "neutral24",
};

export interface CardProps {
  label?: string;
  color?: string;
  shadowed?: boolean;
  onClick?: () => void;
}

export function Card({ label, color, shadowed, onClick }: CardProps) {
  return (
    <Button
      variant="raised"
      color={color ? ColorMap[color] : "neutral32"}
      css={css`
        text-transform: uppercase;
        margin: 0.5rem;
        opacity: ${shadowed ? 0.25 : 1};

        @media (min-width: 1024px) {
          font-size: 1.5rem;
        }
      `}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
