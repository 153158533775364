import { darken, lighten } from "polished";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { Button } from "../Button";
import { easeInQuad, easeOutQuad } from "../../utils/easings";

const Backdrop = styled.div<{ transitionDuration: number; visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(rgb(0 0 0 / 25%), rgb(0 0 0 / 75%));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all ${(props) => props.transitionDuration}ms ${easeInQuad};
  opacity: ${(props) => (props.visible ? "1" : "0")};
`;

const Container = styled.div<{ transitionDuration: number; visible?: boolean }>`
  position: relative;
  width: 40rem;
  min-height: 16rem;
  background: ${(props) => props.theme.colors.neutral24};
  border-radius: 6px;
  box-shadow: 0 6px 0 0 ${(props) => darken(0.05, props.theme.colors.neutral24)};

  display: flex;
  flex-direction: column;

  transition: all ${(props) => props.transitionDuration}ms ${easeOutQuad};
  margin-top: ${(props) => (props.visible ? 0 : -100)}px;

  &::before {
    content: "";
    box-shadow: 0px 6px 12px 2px rgb(0 0 0 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;

const Header = styled.div`
  flex-grow: 0;
  padding: 1.15rem 1rem;
  border-bottom: 1px solid
    ${(props) => lighten(0.05, props.theme.colors.neutral24)};

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  flex-grow: 1;
`;

const CloseButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 1.5rem 1rem;
`;

const Footer = styled.div`
  flex-grow: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;

  display: flex;
  justify-content: flex-end;
`;

export interface ModalProps {
  visible?: boolean;
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  onClose?: () => any;
}

export function Modal({
  visible,
  header,
  children,
  footer,
  onClose,
}: ModalProps): JSX.Element | null {
  const transitionDuration = 300;

  const [disabled, setDisabled] = useState(true);
  const [entering, setEntering] = useState(false);

  useEffect(() => {
    if (visible) {
      setDisabled(false);
      // wait a cycle for the element to mount, then start fading in animation
      setTimeout(() => {
        setEntering(true);
      }, 1);
    } else {
      setEntering(false);
      // wait fade out animation to finish, then disable the component (unmount element)
      setTimeout(() => {
        setDisabled(true);
      }, transitionDuration);
    }
  }, [visible]);

  if (disabled) {
    return null;
  }

  return (
    <Backdrop visible={entering} transitionDuration={transitionDuration}>
      <Container visible={entering} transitionDuration={transitionDuration}>
        <Header>
          <Title>{header}</Title>
          <CloseButton>
            <Button onClick={onClose}>
              <FiX size={32} />
            </Button>
          </CloseButton>
        </Header>
        <Content>{children}</Content>
        <Footer>{footer}</Footer>
      </Container>
    </Backdrop>
  );
}
