import { useState } from "react";

import { useGame } from "../../hooks/useGame";
import { Role } from "../../models/Role";
import { Board } from "../Board";
import { Lobby } from "../Lobby";
import { Toolbar } from "../Toolbar";

export interface GameProps {
  canSpy?: boolean;
  canFlip?: boolean;
  canAdmin?: boolean;
}

export function Game({ canAdmin, canSpy, canFlip }: GameProps) {
  const { gameState, restartGame, flipWord } = useGame();

  const [role, setRole] = useState<Role>();

  if (!gameState) {
    // TODO: game not found
    return null;
  }

  if (!role) {
    return <Lobby gameState={gameState} onJoin={setRole} />;
  }

  return (
    <>
      <Board
        gameState={gameState}
        flipWord={flipWord}
        canSpy={role === "spymaster"}
        canFlip={role === "gamemaster"}
      />

      {role === "gamemaster" && <Toolbar onRestart={restartGame} />}
    </>
  );
}
