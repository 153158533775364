export const easeInSine = "cubic-bezier(0.12, 0, 0.39, 0)";
export const easeOutSine = "cubic-bezier(0.61, 1, 0.88, 1)";
export const easeInOutSine = "cubic-bezier(0.37, 0, 0.63, 1)";
export const easeInQuad = "cubic-bezier(0.11, 0, 0.5, 0)";
export const easeOutQuad = "cubic-bezier(0.5, 1, 0.89, 1)";
export const easeInOutQuad = "cubic-bezier(0.45, 0, 0.55, 1)";
export const easeInCubic = "cubic-bezier(0.32, 0, 0.67, 0)";
export const easeOutCubic = "cubic-bezier(0.33, 1, 0.68, 1)";
export const easeInOutCubic = "cubic-bezier(0.65, 0, 0.35, 1)";
export const easeInQuart = "cubic-bezier(0.5, 0, 0.75, 0)";
export const easeOutQuart = "cubic-bezier(0.25, 1, 0.5, 1)";
export const easeInOutQuart = "cubic-bezier(0.76, 0, 0.24, 1)";
export const easeInQuint = "cubic-bezier(0.64, 0, 0.78, 0)";
export const easeOutQuint = "cubic-bezier(0.22, 1, 0.36, 1)";
export const easeInOutQuint = "cubic-bezier(0.83, 0, 0.17, 1)";
export const easeInExpo = "cubic-bezier(0.7, 0, 0.84, 0)";
export const easeOutExpo = "cubic-bezier(0.16, 1, 0.3, 1)";
export const easeInOutExpo = "cubic-bezier(0.87, 0, 0.13, 1)";
export const easeInCirc = "cubic-bezier(0.55, 0, 1, 0.45)";
export const easeOutCirc = "cubic-bezier(0, 0.55, 0.45, 1)";
export const easeInOutCirc = "cubic-bezier(0.85, 0, 0.15, 1)";
export const easeInBack = "cubic-bezier(0.36, 0, 0.66, -0.56)";
export const easeOutBack = "cubic-bezier(0.34, 1.56, 0.64, 1)";
export const easeInOutBack = "cubic-bezier(0.68, -0.6, 0.32, 1.6)";
