export const defaultTheme = {
  colors: {
    primary: "#3867d6",

    neutral100: "#ffffff",
    neutral96: "#d1d8e0",
    neutral64: "#a5b1c2",
    neutral48: "#778ca3",
    neutral32: "#4b6584",
    neutral24: "#1b2128",

    red: "#eb3b5a",
    orange: "#fa8231",
    yellow: "#f7b731",
    green: "#20bf6b",
    turquoise: "#0fb9b1",
    blue: "#2d98da",
    purple: "#8854d0",
  },
};
